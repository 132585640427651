export const ROUTE_PARTS = Object.freeze({
	acceptConditions: 'accepteer-voorwaarden',
	account: 'account',
	actualisation: 'actualisatie',
	admin: 'admin',
	assignment: 'opdracht',
	assignments: 'opdrachten',
	beheer: 'beheer',
	bookmarks: 'bladwijzers',
	bundle: 'bundel',
	bundles: 'bundels',
	collection: 'collectie',
	collections: 'collecties',
	completeProfile: 'vervolledig-profiel',
	content: 'content',
	contentTab: 'inhoud',
	contentPageLabels: 'content-pagina-labels',
	cookiePolicy: 'cookiebeleid',
	create: 'maak',
	detail: 'detail',
	edit: 'bewerk',
	email: 'email',
	error: 'error',
	feedback: 'feedback',
	forTeachers: 'voor-leerkrachten',
	help: 'hulp',
	interactiveTours: 'interactieve-tours',
	item: 'item',
	items: 'items',
	klaar: 'klaar',
	linkYourAccount: 'link-uw-account',
	linkedAccounts: 'koppelingen',
	loggedInHome: 'start',
	login: 'login',
	logout: 'uitloggen',
	manualAccessRequest: 'manuele-toegangsaanvraag',
	marcom: 'marcom',
	navigation: 'navigatie',
	news: 'nieuws',
	notifications: 'notificaties',
	organisationContent: 'organisatie-content',
	permissionGroups: 'permissie-groepen',
	permissions: 'permissies',
	profile: 'profiel',
	projects: 'projecten',
	publicationDetails: 'publicatie-details',
	publishItems: 'items-publiceren',
	pupilCollections: 'leerlingen-collecties',
	pupilOrTeacher: 'leerling-of-leerkracht',
	pupils: 'leerlingen',
	qualitycheck: 'kwaliteitscontrole',
	quickLane: 'bekijk',
	register: 'registreren',
	registerOrLogin: 'registreer-of-login',
	response: 'antwoord',
	responses: 'antwoorden',
	search: 'zoeken',
	searchQuery: 'zoekopdracht',
	settings: 'instellingen',
	stamboek: 'stamboek',
	studentTeacher: 'student-leerkracht',
	translations: 'vertaling',
	users: 'gebruikers',
	userGroup: 'gebruikersgroep',
	workspace: 'werkruimte',
	alerts: 'meldingen',
	emailPreferences: 'email-voorkeuren',
});
